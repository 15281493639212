
export function setupTheme() {
  
  // Set default animation duration to 300 ms
  $.fx.speeds._default = 300

	// Drowdown handlers
	$('.tg.dropdown_menu a').attr('tabindex', -1)
	$('.tg.dropdown_toggle').click( function() {
		var $dropdown = $(this).siblings('.tg.dropdown_menu')
		$('.tg.dropdown_menu').not($dropdown).removeClass('-open')
		$dropdown.toggleClass('-open').find('input:eq(0)').focus()
		$dropdown.find('a').attr('tabindex', ( $dropdown.hasClass('-open') ? '' : -1 ))
		return false
	})

	$('.tg.nav, .tg.topbox, .tg.dropdown_menu').click( function(e) {
		e.stopPropagation()
	})

	$('.tg.topbox_toggle-nav').click( function() {
		$('.tg.site').toggleClass('-reveal-off-canvas')
	})

	$('body').click( function() {
		$('.tg.dropdown_menu').removeClass('-open').find('a').attr('tabindex', -1)
		$('.tg.site').removeClass('-reveal-off-canvas')
	})

	// SVG fallback for older browsers
	if ( ! document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1') ) {
		$('img[src$="svg"]').attr('src', function() {
			return $(this).attr('src').replace('.svg', '.png')
		})
	}

	// TextGridRep handlers
	// TODO: Move to another file

	var $sidebar = $('.tgrep.sidebar')
	$('.tgrep.sidebar-toggle_button').click( function() {
		$(this).siblings().addBack().toggle()
		if ( $(this).hasClass('-show') ) {
			$sidebar.slideDown()
		} else {
			$sidebar.slideUp({
				done: function() {
					// Remove inline style to prevent sidebar from
					// being hidden when viewport width increases
					$sidebar.css('display', '')
				}
			})
		}
	})

	$('.tgrep.sidebar_expand').click( function() {
		$(this)
			.hide()
			.siblings('.tgrep.sidebar_list').children(':hidden').show()
	})

	var $toggleAllButtons = $('.tgrep.header_button').filter('.-expand-all, .-collapse-all')
	$toggleAllButtons.click( function() {
		$toggleAllButtons.toggle()
		if ( $(this).hasClass('-expand-all') ) {
			$('.tgrep.metadata_button.-show:visible').click()
		} else {
			$('.tgrep.metadata_button.-hide:visible').click()
		}
	})

	$('.tgrep.metadata_button').click( function() {
		$(this).siblings('.tgrep.metadata_button').addBack().toggle()
		$(this).siblings('.tgrep.metadata_toggle').slideToggle()
	})

	$('.tgrep.advanced-search_button.-toggle').click( function() {
		$(this).toggleClass('-open')
		$( '#' + $(this).data('target') ).slideToggle()
	})

	$('.tgrep.advanced-search_button.-select').click( function() {
		const checked = $(this).hasClass('-all')
		$( '#' + $(this).data('target') ).find(':checkbox').prop('checked', checked)
	})

	// Add or remove metadata search fields
	$('.tgrep.advanced-search_button.-item').click( function() {
		var $container = $(this).closest('.tgrep.advanced-search_grow-group')
		if ( $(this).hasClass('-add') ) {
			let $template = $(this).closest('.tgrep.advanced-search_grow-item')
			let $clone = $template.clone(true)
			$clone.find(':input').val('')
			$clone.find('select').each( function() {
				$(this).val( $(this).find('option:first').val() )
			})
			$clone.insertAfter($template)
		} else {
			$(this).closest('.tgrep.advanced-search_grow-item').remove()
		}
		var $items = $container.find('.tgrep.advanced-search_grow-item')
		$container.find('.tgrep.advanced-search_button.-item.-remove').prop('disabled', ($items.length < 2))
	})

	// :)
	var $eyeTrigger = $('<i class="eye -trigger">')
	$('.tg.header_logo a').append($eyeTrigger)
	$eyeTrigger.click( function() {
		var $eyeLeft = $('<i class="eye -left">'),
			$eyeRight = $('<i class="eye -right">')
		$(this).after($eyeLeft, $eyeRight).remove()
		$(window).mousemove( function(e) {
			$eyeLeft.css('transform', 'rotate(' + ( Math.atan((e.pageY - $eyeLeft.offset().top - 12) / (e.pageX - $eyeLeft.offset().left - 12)) + (e.pageX <= $eyeLeft.offset().left + 12 ? 4.19 : 1.05) ) + 'rad)')
			$eyeRight.css('transform', 'rotate(' + ( Math.atan((e.pageY - $eyeRight.offset().top - 12) / (e.pageX - $eyeRight.offset().left - 12)) + (e.pageX <= $eyeRight.offset().left + 12 ? 4.19 : 1.05) ) + 'rad)')
		})
		return false
	})

}

