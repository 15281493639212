/* functions for interacting with the shelf */

/* show number of items on shelf */
export function setShelfCount(num) {
  $('.topbox_shelf-count').each((id, elem) => {
    elem.textContent = num;
  })
}

export function setupShelfButtons(isShelf) {

  const removeLabel = document.querySelector('#i18n-remove-from-shelf-label').dataset.value;
  const addLabel = document.querySelector('#i18n-add-to-shelf-label').dataset.value;

  $(document).on('click', '.-remove-from-shelf', function(e) {
    var $this = $(this);
    var tgObjectUri = $this.attr('data-textgrid-targeturi');

    $.ajax({
      url: '/service/shelf/remove' ,
      type: 'POST',
      dataType:'json',
      data: {
        uri: tgObjectUri
      },
      success: function(data) {
        if(isShelf) {
          // if this remove button is on the shelf, the whole item is removed
          $this.closest('li.tgrep.result').remove();
          $this.closest('li.tgrep.gallery-item').remove();
          $('.tgrep.header_count').html(data);
          removeFromAggregatorItemString(tgObjectUri)
        } else {
          $this.removeClass('-remove-from-shelf');
          $this.addClass('-add-to-shelf');
          $this.html(addLabel);
        }
        setShelfCount(data);
      }
    });
  });

  // the shelf has no add buttons
  if(!isShelf) {
    $(document).on('click', '.-add-to-shelf', function(e) {
      var $this = $(this);
      $.ajax({
        url: "/service/shelf/add" ,
        type: 'POST',
        dataType:'json',
        data: {
          uri: $this.attr('data-textgrid-targeturi')
        },
        success: function(data) {
          $this.removeClass('-add-to-shelf');
          $this.addClass('-remove-from-shelf');
          $this.html(removeLabel);
          setShelfCount(data);
        }
      });
    });
  }
}

export function setupShelf() {
  
  setupShelfButtons(true);
  
  $(document).on('click', '.tgrep.header_button.-clear', function(e) {
    $.ajax({
      url: '/service/shelf/clear' ,
      type: 'POST',
      dataType:'json',
      data: {
        empty: 'empty'
      },
      success: function(data) {
        $('.tgrep.results_list').empty();
        $('.tgrep.results_gallery').empty();
        $('.tgrep.header_count').html(0);
        setShelfCount(data);
        removeUselessActionsFromEmptyShelf();
      }
    });
  });
  
  shelfItemString = document.querySelector('#shelfItemString').dataset.value;
}

//set by jsp
var shelfItemString;

// removes the tguri from aggregatorUrl
function removeFromAggregatorItemString(tgObjectUri) {

  var shelfItemStringOld = shelfItemString;
  var itemArr = shelfItemString.split(',');
  itemArr.splice(itemArr.indexOf(tgObjectUri),1);

  // are there any objects left on the shelf?
  if(itemArr.length > 0) {
    shelfItemString = itemArr.join(',');
    $('.aggregator-items').each((id, elem) => {
        elem.href = elem.href.replace(shelfItemStringOld, shelfItemString);
    });
  } else {
    removeUselessActionsFromEmptyShelf();
  }
}

// disable download and voyant links if shelf is empty
function removeUselessActionsFromEmptyShelf() {
  $('.remove_on_shelf_empty').each((id, elem) => {
    elem.remove();
  });
}




/* localstorage */
export function hasLocalStorage() {
  const item = "tgrep"
    try {
      window.localStorage.setItem(item, item);
      window.localStorage.removeItem(item);
      return true;
    } catch(e) {
      return false;
    }
}
