
export function setupAdvancedSearch() {

  $('form.advanced-search').submit(function(e) {
    e.preventDefault();

    var query = "";

    /**
     * serialize form for accessing fulltext, contentType and wordDistance params
     */
    var formArray = $("form.advanced-search").serializeArray();

    var fulltext;
    var ctypeArr = [];
    var wordDistance;
    var wordDistanceActive = false;
    var mdSearchActive = false;
    var contentSearchActive = false;

    $(formArray).each(function(id, val) {
      if(val.name === 'fulltext') {
        fulltext = val.value;
      }
      if(val.name === 'contenttype') {
        contentSearchActive = true;
        ctypeArr.push(val.value);
      }
      if(val.name === 'wordDistanceSel') {
        wordDistanceActive = val.value === 'true';
      }
      if(val.name === 'wordDistance') {
        wordDistance = val.value;
      }
      if(val.name === 'metadata-term') {
        if (val.value.length > 0 ) {
          mdSearchActive = true;
        }
      }
    });

    /**
     * handle metadata query
     */	
    if(mdSearchActive) {
      $.each($('.metadata-searchgroup'), function(id, val) {

        var field = $(val).find('[name="metadata-field"]').val();
        query += '(';

        $.each($(val).find('[name="metadata-term"]'), function(termIndex, termInput) {	

          var term = $(termInput).val();

          if(term !== '' && term !== true) {
            query += field + ':"' + term + '" OR ';
          }
        });

        /* cut the last "OR" */
        query = query.substring(0, query.length-4);

        query += ')';
        query += ' AND ';
      });

      /* cut the last "AND" */
      query = query.substring(0, query.length-5);
    }

    /**
     * handle content type query
     */
    if(ctypeArr.length > 0) {

      if(query !== "") query += ' AND ';
      if(ctypeArr.length > 1) {
        query += '(';
      }
      $.each(ctypeArr, function() {
        query += 'format:"'+this+'" OR ';
      });

      /* cut the last "OR" */
      query = query.substring(0, query.length-4);

      if(ctypeArr.length > 1) {
        query += ")";
      }
    }


    /**
     * fulltext
     */
    if(fulltext !== "") {
      if(! $.isEmptyObject(fulltext)) {
        if(query !== "") {
          query += ' AND ';
        }

        if( wordDistanceActive ) {
          query += '"'+fulltext+'"~'+wordDistance;
        } else {
          query += fulltext;

        }
      }
    }

    location.href="/search?query="+query;
  });

}