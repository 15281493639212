import { setupTheme } from './theme.js';
import { setupSearchResultsPage } from './search.js';
import { setupAdvancedSearch } from './advanced-search.js';
import { setShelfCount, setupShelf, setupShelfButtons } from './shelf.js';

document.addEventListener("DOMContentLoaded", function(event) {

  /* setup for all pages */
  /* retrieve number of shelf items on page load to set number display */
  $.ajax("/service/shelf/count").done(response => setShelfCount(response));
  setupTheme();
  
  /* page specific setup 
     find out which page we are on to apply page specific functionality (if needed)
     the body should have a class with this info
  */
  const bodyClasses = document.querySelector('body').classList;
  if(bodyClasses.contains('shelf')) {
  	setupShelf();
  } 
  if(bodyClasses.contains('browse')) {
  	setupShelfButtons(false);
  	// the browse.jsp has an iframe when showing html directly loaded from tgcrud
  	$("#htmlIframe").contents().find("body").attr("style","margin-left: 0");
  }
  if(bodyClasses.contains('search')) {
    setupShelfButtons(false);
    setupSearchResultsPage();
  }
  
  if(bodyClasses.contains('advanced-search')) {
    setupAdvancedSearch();
  }

});