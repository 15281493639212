
export function setupSearchResultsPage() {
  
  var filters = [];
  // get the filters from the left sidebar to append them to the search form
  $('.tgrep.sidebar_item.-filter').each((id, elem) => {
    filters.push(elem.dataset.filter);
  });
  
  $('#search-filters-active').change(function() {
    if($('#search-filters-active').is(':checked')){
      addFiltersToSearchForm(filters);
    } else {
      removeFiltersFromSearchForm();
    }
  });
  
  addFiltersToSearchForm(filters);
}

function addFiltersToSearchForm(filters) {
  $(filters).each(function(id, filter) {
    $('form.search').append($('<input type="hidden" class="hiddenFilterInput" name="filter" value="'+filter+'" />'));
  });
}

function removeFiltersFromSearchForm() {
  $('.hiddenFilterInput').remove();
}
